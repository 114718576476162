<template>
  <div v-if="$ability.can('read', this.$route.meta.resource)">
    <b-overlay
      :show="show"
      rounded="sm"
    >
      <b-card
        no-body
        class="mb-2"
      >
        <b-card-header class="p-1">
          <b-card-title class="font-medium-2">
            <feather-icon
              icon="BookIcon"
              size="18"
            />
            <span class="align-middle ml-50">{{ type.toUpperCase() }}</span>
          </b-card-title>
        </b-card-header>
        <validation-observer ref="formData">
          <b-form class="p-2">
            <b-row>
              <b-col
                v-if="!statusTable"
                cols="6"
              >
                <!-- refId -->
                <b-form-group
                  :label="$t('Amount')"
                  label-for="register-Amount"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Amount"
                    vid="Amount"
                    rules="required"
                  >
                    <b-form-input
                      id="register-Amount"
                      v-model="amount"
                      name="register-Amount"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('Amount')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                v-if="!statusTable"
                cols="6"
              >
                <b-form-group
                  :label="$t('Options')"
                  label-for="register-Options"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Options"
                    vid="Options"
                    rules="required"
                  >
                    <v-select
                      id="register-Options"
                      v-model="option"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="name"
                      :options="Options"
                      :reduce="(Options) => Options.value"
                      class="select-size-lg"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider></b-form-group>
              </b-col>

              <b-col cols="6">
                <b-form-group
                  :label="$t('Service Area')"
                  label-for="register-serviceAreaId"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="serviceAreaId"
                    vid="serviceAreaId"
                    rules=""
                  >
                    <v-select
                      id="register-serviceAreaId"
                      v-model="serviceAreaId"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="name"
                      :options="serviceAreaData"
                      :reduce="(serviceAreaData) => serviceAreaData._id"
                      class="select-size-lg"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider></b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  :label="$t('Status Table')"
                  label-for="register-statusTable"
                >
                  <b-form-checkbox
                    v-model="statusTable"
                    switch
                    inline
                  />
                </b-form-group>
              </b-col>
              <b-col
                v-if="statusTable"
                cols="12"
              >
                <!-- refId -->
                <b-form-group
                  :label="$t('Table Distance')"
                  label-for="register-TableDistance"
                >
                  <b-table
                    striped
                    responsive
                    class="mb-0"
                    :items="tableDistance"
                    :fields="tableDistanceColumns"
                    style="height: 60vh;"
                  >
                    <template #cell(start)="data">
                      <!-- <b-form-input
                        :id="'start'+data.index"
                        v-model="tableDistance[data.index].start"
                        name="start"
                        :placeholder="$t('Start distance')"
                        :disabled="data.index === 0 ? true : false"
                      /> -->
                      <cleave
                        :id="'start'+data.index"
                        v-model="tableDistance[data.index].start"
                        class="form-control"
                        :raw="false"
                        :options="optionCleave.number"
                        :placeholder="$t('Start distance')"
                        :disabled="data.index === 0 ? true : false"
                      />
                      <small
                        v-if="checkStartDistance(tableDistance[data.index],data.index)"
                        class="text-danger"
                      >{{ $t('The end distance of the table above must be less than') }}</small>
                    </template>
                    <template #cell(end)="data">
                      <!-- <b-form-input
                        :id="'end'+data.index"
                        v-model="tableDistance[data.index].end"
                        name="end"
                        :placeholder="$t('End distance')"
                      /> -->
                      <cleave
                        :id="'end'+data.index"
                        v-model="tableDistance[data.index].end"
                        class="form-control"
                        :raw="false"
                        :options="optionCleave.number"
                        :placeholder="$t('End distance')"
                      />
                      <small
                        v-if="checkEndDistance(tableDistance[data.index],data.index)"
                        class="text-danger"
                      >{{ $t('The starting distance must be less than the ending distance') }}</small>
                    </template>
                    <template #cell(price)="data">
                      <!-- <b-form-input
                        :id="'price'+data.index"
                        v-model="tableDistance[data.index].price"
                        name="price"
                        :placeholder="$t('Price')"
                      /> -->
                      <cleave
                        :id="'price'+data.index"
                        v-model="tableDistance[data.index].price"
                        class="form-control"
                        :raw="false"
                        :options="optionCleave.number"
                        :placeholder="$t('Price')"
                      />
                      <small
                        v-if="tableDistance[data.index].price === '' || tableDistance[data.index].price === null"
                        class="text-danger"
                      >{{ $t('Please enter price') }}</small>
                    </template>
                    <template #cell(action)="data">
                      <b-row>
                        <b-button
                          v-if="data.index === tableDistance.length-1"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="primary"
                          class="d-flex"
                          @click="addTableDistance(data.index)"
                        >
                          <feather-icon
                            icon="PlusIcon"
                          />
                        </b-button>
                        <b-button
                          v-if="data.index !== 0"

                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="danger"
                          class="d-flex"
                          @click="deleteTableDistance(data.index)"
                        >
                          <feather-icon
                            icon="TrashIcon"
                          />
                        </b-button>
                      </b-row>

                    </template>
                  </b-table>
                </b-form-group>
              </b-col>
              <b-col
                v-if="statusTable"
                cols="12"
              >
                <!-- refId -->
                <b-form-group
                  :label="$t('Select Option')"
                  label-for="register-selectOption"
                >
                  <b-table
                    striped
                    responsive
                    class="mb-0"
                    :items="selectOption"
                    :fields="tableSelectOptionColumns"
                    style="height: 60vh;"
                  >
                    <template #cell(name)="data">
                      <b-col
                        v-for="(item , index) in language"
                        :key="index+'name'"
                        cols="12"
                      >
                        <b-form-group
                          :label="item.name"
                          :label-for="item.name+'name'"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="item.name+'name'"
                            :vid="item.name+'name'"
                          >
                            <b-form-input
                              v-if="selectOption[data.index].name[index]"
                              :id="item.name+'name'"
                              :name="item.name+'name'"
                              :value="selectOption[data.index].name[index]['value']"
                              :state="errors.length > 0 ? false:null"
                              :placeholder="item.name"
                              :disabled="type === 'call' ? true : false "
                              @input="inputLanguage($event,item.lang ,index, data.index)"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </template>
                    <template #cell(price)="data">
                      <validation-provider
                        #default="{ errors }"
                        name="Price"
                        vid="price"
                        rules="min:1"
                      >
                        <b-form-input
                          :id="'price'+data.index"
                          v-model="selectOption[data.index].price"
                          name="price"
                          :placeholder="$t('Price')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>

                    </template>
                    <template #cell(action)="data">
                      <b-button
                        v-if="type !== 'call' && data.index === selectOption.length-1"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        class="d-flex"
                        @click="addTableOption()"
                      >
                        <feather-icon
                          icon="PlusIcon"
                        />
                      </b-button>
                      <b-button
                        v-if="type !== 'call' && data.index !== selectOption.length-1"

                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="danger"
                        class="d-flex"
                        @click="deleteTableOption(data.index)"
                      >
                        <feather-icon
                          icon="TrashIcon"
                        />
                      </b-button>
                    </template>
                  </b-table>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Action button -->
            <div class="d-flex mt-2">
              <b-button
                variant="primary"
                class="mr-2"
                @click="validationForm()"
              >
                {{ $t("Submit") }}
              </b-button>
              <b-button
                type="button"
                variant="outline-secondary"
                @click="cancel"
              >
                {{ $t("Cancel") }}
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCardHeader,
  BCardTitle,
  BCard,
  BRow,
  BCol,
  BForm,
  BFormInput,
  BFormGroup,
  BFormCheckbox,
  BButton,
  BOverlay,
  BTable,
} from 'bootstrap-vue'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import { ref } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import storeModule from '@/store/services/hero/setupDelivery'
import storeModuleServiceArea from '@/store/services/hero/serviceArea'
import storeModuleDriverType from '@/store/services/hero/driverType'

const STORE_MODULE_NAME = 'setupDelivery'
const STORE_MODULE_NAME_SERVICE_AREA = 'serviceArea'
const STORE_MODULE_NAME_DRIVER_TYPE = 'driverType'

export default {
  components: {
    Cleave,
    ValidationProvider,
    ValidationObserver,
    BCardHeader,
    BCardTitle,
    BCard,
    BRow,
    BCol,
    BForm,
    BFormInput,
    BFormGroup,
    // eslint-disable-next-line vue/no-unused-components
    BFormCheckbox,
    BButton,
    BOverlay,
    BTable,
    vSelect,

  },
  directives: {
    Ripple,
  },
  data() {
    return {
      show: false,
      dataId: null,
      serviceAreaId: null,
      amount: '',
      type: '',
      option: 'constant',
      statusTable: false,
      tableDistance: [
        {
          start: '0', // ระยะทางเริ่มต้น
          end: '', // ระยะสิ้นสุด
          price: '', // ราคา
        },
      ],
      selectOption: [
      ],
      optionCleave: {
        number: {
          numeral: true,
        },
      },
      required,
    }
  },
  computed: {
    serviceAreaData() {
      return store.state[STORE_MODULE_NAME_SERVICE_AREA].respData != null ? store.state[STORE_MODULE_NAME_SERVICE_AREA].respData.data : []
    },
    language() {
      return store.state.appConfig.systemConfig ? store.state.appConfig.systemConfig.language : []
    },
    Options() {
      return [
        { name: this.$t('Constant'.toUpperCase()), value: 'constant' },
        { name: this.$t('Percent'.toUpperCase()), value: 'percent' },
      ]
    },
    tableDistanceColumns() {
      return [
        { key: 'start', label: this.$t('Start distance'), sortable: false },
        { key: 'end', label: this.$t('End distance'), sortable: false },
        { key: 'price', label: this.$t('Price'), sortable: false },
        { key: 'action', label: this.$t('Action'), sortable: false },
      ]
    },
    tableSelectOptionColumns() {
      return [
        { key: 'name', label: this.$t('Name'), sortable: false },
        { key: 'price', label: this.$t('Price'), sortable: false },
        { key: 'action', label: this.$t('Action'), sortable: false },
      ]
    },
    // eslint-disable-next-line vue/return-in-computed-property
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      const type = this.$route.path.split('/')
      this.type = type[type.length - 2]

      if (this.$route.params.id !== 'add') {
        const obj = {
          id: this.$route.params.id,
          type: this.type,
        }

        this.show = true
        store
          .dispatch(`${STORE_MODULE_NAME}/view`, obj)
        // eslint-disable-next-line no-unused-vars
          .then(result => {
            this.show = false
            const respData = result.data.data
            this.selectedData(respData)
            // console.log('fetch Success : ', result)
            // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
          // eslint-disable-next-line no-unused-vars
          }).catch(error => {
            this.show = false
            this.$toast({
              component: ToastificationContent,
              position: 'bottom-right',
              props: {
                title: this.$t('Error'),
                icon: 'ErrorIcon',
                variant: 'danger',
                text: this.$t(error.response.data.message),
              },
            })
            // console.log('fetchUsers Error : ', error)
            // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
          })
      }
      if (this.type !== 'call') {
        const name = []
        for (let index = 0; index < this.language.length; index += 1) {
          name.push({
            lang: this.language[index].lang,
            value: '',
          })
        }
        this.selectOption.push({
          name,
          price: 0,
        })
      } else {
        this.show = true
        store
          .dispatch(`${STORE_MODULE_NAME_DRIVER_TYPE}/get`, {
            currentPage: 0,
            pageSize: 0,
            searchQuery: '',
          })
        // eslint-disable-next-line no-unused-vars
          .then(async result => {
            this.show = false
            const { data } = result.data
            if (data.length > 0) {
              for (let index = 0; index < data.length; index += 1) {
                const indexSelectOption = this.selectOption.findIndex(p => p.partnerDriverTypeId === data[index]._id)
                if (indexSelectOption < 0) {
                  this.selectOption.push({
                    partnerDriverTypeId: data[index]._id, // พื้นที่ให้บริการ
                    name: data[index].name,
                    price: 0, // ราคา
                  })
                } else {
                  this.selectOption[indexSelectOption].name = data[index].name
                }
              }
              this.selectOption = this.selectOption.filter(p => p.partnerDriverTypeId !== null)
            } else {
              this.selectOption = []
            }

            // console.log('fetch Success : ', result)
            // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
          // eslint-disable-next-line no-unused-vars
          }).catch(error => {
            this.show = false
            this.$toast({
              component: ToastificationContent,
              position: 'bottom-right',
              props: {
                title: this.$t('Error'),
                icon: 'ErrorIcon',
                variant: 'danger',
                text: this.$t(error.response.data.message),
              },
            })
            // console.log('fetchUsers Error : ', error)
            // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
          })
      }
      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME_SERVICE_AREA}/get`, {
          currentPage: 0,
          pageSize: 0,
          searchQuery: '',
        })
      // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.show = false
        // console.log('fetch Success : ', result)
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
          // eslint-disable-next-line no-unused-vars
        }).catch(error => {
          this.show = false
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
  },
  created() {
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)
    if (!store.hasModule(STORE_MODULE_NAME_SERVICE_AREA)) store.registerModule(STORE_MODULE_NAME_SERVICE_AREA, storeModuleServiceArea)
    if (!store.hasModule(STORE_MODULE_NAME_DRIVER_TYPE)) store.registerModule(STORE_MODULE_NAME_DRIVER_TYPE, storeModuleDriverType)
    const type = this.$route.path.split('/')
    this.type = type[type.length - 2]
    console.log(this.type)
    if (this.$route.params.id !== 'add') {
      const obj = {
        id: this.$route.params.id,
        type: this.type,
      }

      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME}/view`, obj)
      // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.show = false
          const respData = result.data.data
          this.selectedData(respData)
        // console.log('fetch Success : ', result)
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
          // eslint-disable-next-line no-unused-vars
        }).catch(error => {
          this.show = false
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    }
    if (this.type !== 'call') {
      const name = []
      for (let index = 0; index < this.language.length; index += 1) {
        name.push({
          lang: this.language[index].lang,
          value: '',
        })
      }
      this.selectOption.push({
        name,
        price: 0,
      })
    } else {
      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME_DRIVER_TYPE}/get`, {
          currentPage: 0,
          pageSize: 0,
          searchQuery: '',
        })
      // eslint-disable-next-line no-unused-vars
        .then(async result => {
          this.show = false
          const { data } = result.data
          if (data.length > 0) {
            for (let index = 0; index < data.length; index += 1) {
              const indexSelectOption = this.selectOption.findIndex(p => p.partnerDriverTypeId === data[index]._id)
              if (indexSelectOption < 0) {
                this.selectOption.push({
                  partnerDriverTypeId: data[index]._id, // พื้นที่ให้บริการ
                  name: data[index].name,
                  price: 0, // ราคา
                })
              } else {
                this.selectOption[indexSelectOption].name = data[index].name
              }
            }
            this.selectOption = this.selectOption.filter(p => p.partnerDriverTypeId !== null)
          } else {
            this.selectOption = []
          }

        // console.log('fetch Success : ', result)
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
          // eslint-disable-next-line no-unused-vars
        }).catch(error => {
          this.show = false
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    }
    this.show = true
    store
      .dispatch(`${STORE_MODULE_NAME_SERVICE_AREA}/get`, {
        currentPage: 0,
        pageSize: 0,
        searchQuery: '',
      })
      // eslint-disable-next-line no-unused-vars
      .then(result => {
        this.show = false
        // console.log('fetch Success : ', result)
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
      // eslint-disable-next-line no-unused-vars
      }).catch(error => {
        this.show = false
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: this.$t('Error'),
            icon: 'ErrorIcon',
            variant: 'danger',
            text: this.$t(error.response.data.message),
          },
        })
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
      })
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    if (store.hasModule(STORE_MODULE_NAME_SERVICE_AREA)) store.unregisterModule(STORE_MODULE_NAME_SERVICE_AREA)
    if (store.hasModule(STORE_MODULE_NAME_DRIVER_TYPE)) store.unregisterModule(STORE_MODULE_NAME_DRIVER_TYPE)
  },
  setup() {
    return {
      STORE_MODULE_NAME, STORE_MODULE_NAME_SERVICE_AREA, STORE_MODULE_NAME_DRIVER_TYPE,
    }
  },
  methods: {
    checkStartDistance(data, index) {
      if (index > 0) {
        if (data.start !== '' && data.start !== null) {
          if (parseFloat((this.tableDistance[index - 1].end).toString().replace(',', '')) > parseFloat((data.start).toString().replace(',', ''))) {
            return true
          }
        }
      }
      return false
    },
    // eslint-disable-next-line no-unused-vars
    checkEndDistance(data, index) {
      if (data.start !== '' && data.start !== null && data.end !== '' && data.end !== null) {
        if (parseFloat((data.start).toString().replace(',', '')) > parseFloat((data.end).toString().replace(',', ''))) {
          return true
        } if (parseFloat((data.start).toString().replace(',', '')) === parseFloat((data.end).toString().replace(',', ''))) {
          return true
        }
      }
      return false
    },
    inputLanguage(input, lang, index, dataIndex) {
      if (this.selectOption[dataIndex].name[index].lang === lang) {
        this.selectOption[dataIndex].name[index].value = input
      }
    },
    // eslint-disable-next-line no-unused-vars
    selectedData(data) {
      if (data._id) {
        this.dataId = data._id
      }
      if (data.serviceAreaId) {
        this.serviceAreaId = data.serviceAreaId
      }
      if (data.amount) {
        this.amount = data.amount
      }
      if (data.option) {
        this.option = data.option
      }
      if (data.statusTable) {
        this.statusTable = data.statusTable
      }
      if (data.tableDistance) {
        if (data.tableDistance.length > 0) {
          let { tableDistance } = data
          // eslint-disable-next-line no-multi-assign
          tableDistance = tableDistance.map((p, index) => ({
            start: index === 0 ? '0' : p.start,
            end: p.end,
            price: p.price,
          }))
          this.tableDistance = tableDistance
        } else {
          this.tableDistance = [
            {
              start: '0', // ระยะทางเริ่มต้น
              end: '', // ระยะสิ้นสุด
              price: '', // ราคา
            },
          ]
        }
      }
      if (data.selectOption) {
        this.selectOption = data.selectOption
      }
    },
    deleteTableDistance(index) {
      this.tableDistance.splice(index, 1)
      if (this.tableDistance.length > 0) {
        if (this.tableDistance[0].start !== '0') {
          this.tableDistance[0].start = '0'
        }
      }
    },
    addTableDistance(index) {
      this.tableDistance.push({
        start: `'${this.tableDistance[index].end}'`, // ระยะทางเริ่มต้น
        end: '', // ระยะสิ้นสุด
        price: '', // ราคา
      })
    },
    deleteTableOption(index) {
      this.selectOption.splice(index, 1)
    },
    addTableOption() {
      const name = []
      for (let index = 0; index < this.language.length; index += 1) {
        name.push({
          lang: this.language[index].lang,
          value: '',
        })
      }
      this.selectOption.push({
        name,
        price: 0, // ราคา
      })
    },
    validationForm() {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('Do you want to save')}?`, {
          title: this.$t('Please Confirm'),
          cancelVariant: 'outline-secondary',
          okVariant: 'success',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          centered: true,
        })
        .then(value => {
          if (value === true) {
            this.$refs.formData.validate().then(success => {
              if (success) {
                const obj = {
                  id: this.$route.params.id === 'add' ? null : this.$route.params.id,
                  type: this.type,
                  serviceAreaId: this.serviceAreaId,
                  amount: this.amount,
                  option: this.option,
                  statusTable: this.statusTable,
                  tableDistance: this.tableDistance.filter(p => p.start !== '' && p.start !== null && p.end !== '' && p.end !== null && p.price !== '' && p.price !== null).map(p => ({
                    start: p.start.replace(',', ''),
                    end: p.end.replace(',', ''),
                    price: p.price.replace(',', ''),
                  })),
                  selectOption: this.selectOption,
                }
                if (obj.id !== null) {
                  this.show = true

                  store
                    .dispatch(`${STORE_MODULE_NAME}/update`, obj)
                    .then(result => {
                      console.log('fetch Success : ', result)
                      this.show = false
                      this.$router.push({ path: `/hero/setup/delivery/${this.type}` })
                      this.$toast({
                        component: ToastificationContent,
                        position: 'bottom-right',
                        props: {
                          title: this.$t('Success'),
                          icon: 'CheckIcon',
                          variant: 'success',
                          text: this.$t('Success'),
                        },
                      })
                    }).catch(error => {
                      console.log('fetchUsers Error : ', error)
                      this.show = false

                      this.$toast({
                        component: ToastificationContent,
                        position: 'bottom-right',
                        props: {
                          title: this.$t('Error'),
                          icon: 'ErrorIcon',
                          variant: 'danger',
                          text: this.$t(error.response.data.message),
                        },
                      })
                    })
                } else {
                  this.show = true

                  store
                    .dispatch(`${STORE_MODULE_NAME}/add`, obj)
                    .then(result => {
                      console.log('fetch Success : ', result)
                      this.show = false

                      this.$router.push({ path: `/hero/setup/delivery/${this.type}` })

                      this.$toast({
                        component: ToastificationContent,
                        position: 'bottom-right',
                        props: {
                          title: this.$t('Success'),
                          icon: 'CheckIcon',
                          variant: 'success',
                          text: this.$t('Success'),
                        },
                      })
                    }).catch(error => {
                      console.log('fetchUsers Error : ', error)
                      this.show = false

                      this.$toast({
                        component: ToastificationContent,
                        position: 'bottom-right',
                        props: {
                          title: this.$t('Error'),
                          icon: 'ErrorIcon',
                          variant: 'danger',
                          text: this.$t(error.response.data.message),
                        },
                      })
                    })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: this.$t('Error Validator'),
                  },
                })
              }
            })
          }
        })
    },
    cancel() {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('Do you want to cancel')}?`, {
          title: this.$t('Please Confirm'),
          cancelVariant: 'outline-secondary',
          okVariant: 'danger',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          centered: true,
        })
        .then(value => {
          if (value === true) {
            this.$router.push({ path: `/hero/setup/delivery/${this.type}` })
          }
        })
    },
    showToast(variant, position, text, icon, title) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon,
            text,
            // icon: 'InfoIcon',
            // text: 'I do not think that word means what you think it means.',
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

.btn-remove {
  position: absolute;
  top: 20%;
  left: 30%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: red;
  color: white;
  font-size: 12px;
  padding: 12px 16px;
  border: none;
  cursor: pointer;
  border-radius: 15px;
  text-align: center;
}
.imgs-show {
  // width: 100%;
  height: 100px;
}
</style>
